import React from "react";
import * as Chakra from "@chakra-ui/react";
import { HeadingMedium } from "../heading-medium";
import { BodyText } from "../body-text";
import GoogleState from "./google-state-machine";
import { FormScrollArea } from "../form-scroll-area";

interface AdInput {
  title: string;
  value: string;
  placeholder: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  number?: number;
}

const AdInput = ({ title, placeholder, value, onChange, number }: AdInput) => (
  <Chakra.Box pb="17px" width="100%">
    <Chakra.InputGroup>
      <Chakra.InputLeftAddon
        width="20%"
        minWidth="118px"
        fontSize="14px"
        children={title}
      />
      <Chakra.Input
        fontWeight="400"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        width="100%"
        borderRadius="2px"
        height="40px"
        py="14px"
        _placeholder={{ fontSize: "14px", color: "#000", fontWeight: "400" }}
      />
      <Chakra.InputRightElement>
        <Chakra.Text fontSize="13px" color="#c8232a">
          {number ? (number && value ? Math.max(number - value.length, 0) : number) : "NA"}
        </Chakra.Text>
      </Chakra.InputRightElement>
    </Chakra.InputGroup>
  </Chakra.Box>
);

const GoogleOptions = () => {
  const ads = GoogleState.useGlobalState((gs) => gs.ads);
  const focusedAdIndex = GoogleState.useGlobalState((gs) => gs.focusedAdIndex);

  return (
    <>
      <Chakra.Box width="100%" height="100%">
        <Chakra.VStack
          justify="start"
          align="start"
          width="100%"
          height="100%"
          py={{ base: "20px", lg: "80px" }}
          pl={{ base: "20px", lg: "82px" }}
          pr={{ base: "20px", lg: "0" }}
        >
          <FormScrollArea>
            <HeadingMedium>Google Ads</HeadingMedium>
            <BodyText>
              Google Ads campaigns are an effective way to drive potential
              target customers to your landing page while they’re in the process
              of searching for products or solutions just like the one that
              you’re testing.
            </BodyText>
            <Chakra.Tabs
              variant="enclosed"
              width="100%"
              index={focusedAdIndex}
              onChange={GoogleState.setFocusedAd}
            >
              <Chakra.TabList borderRadius="0px">
                {ads.map((_, i) => (
                  <Chakra.Tab
                    padding="8px"
                    fontSize="14px"
                    letterSpacing="0.11px"
                    lineHeight="16px"
                    height="100%"
                    borderRadius="0px"
                    _focus={{ borderColor: "transparent" }}
                    _selected={{
                      bg: "white",
                      color: "black",
                      borderColor: "#d8d8d8",
                      borderBottomColor: "white",
                    }}
                    color="#243475"
                  >
                    Ad {i + 1}
                  </Chakra.Tab>
                ))}
              </Chakra.TabList>
              <Chakra.TabPanels>
                {ads.map((ad, i) => (
                  <Chakra.TabPanel px="0">
                    <Chakra.VStack
                      justify="start"
                      align="start"
                      width="100%"
                      pt={{ base: "20px", lg: "70px" }}
                    >
                      <AdInput
                        title="Headline 1"
                        placeholder="Example Headline"
                        number={30}
                        value={ad.headline1}
                        onChange={(e) =>
                          GoogleState.updateHeadline1(e.target.value, i, 30)
                        }
                      />
                      <AdInput
                        title="Headline 2"
                        placeholder="Example Headline"
                        number={30}
                        value={ad.headline2}
                        onChange={(e) =>
                          GoogleState.updateHeadline2(e.target.value, i, 30)
                        }
                      />
                      <AdInput
                        title="Headline 3"
                        placeholder="Example Headline"
                        number={30}
                        value={ad.headline3}
                        onChange={(e) =>
                          GoogleState.updateHeadline3(e.target.value, i, 30)
                        }
                      />
                      <AdInput
                        title="Description 1"
                        placeholder="Example Headline"
                        number={90}
                        value={ad.description1}
                        onChange={(e) =>
                          GoogleState.updateDescription1(e.target.value, i, 90)
                        }
                      />
                      <AdInput
                        title="Description 2"
                        placeholder="Example Headline"
                        number={90}
                        value={ad.description2}
                        onChange={(e) =>
                          GoogleState.updateDescription2(e.target.value, i, 90)
                        }
                      />
                      <AdInput
                        title="Display Url"
                        placeholder="Example Headline"
                        value={ad.displayUrl}
                        onChange={(e) =>
                          GoogleState.updateDisplayUrl(e.target.value, i)
                        }
                      />
                    </Chakra.VStack>
                  </Chakra.TabPanel>
                ))}
              </Chakra.TabPanels>
            </Chakra.Tabs>
          </FormScrollArea>
        </Chakra.VStack>
      </Chakra.Box>
    </>
  );
};
export default GoogleOptions;
