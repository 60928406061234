import React from "react";
import * as Chakra from "@chakra-ui/react";
import { Link, PageProps } from "gatsby";
import { Seo } from "../components/seo";
import GoogleOptions from "../components/google/google-options";
import NextPageButton from "../components/next-page-button";
import { GoogleMockup } from "../components/google/google-mockup";
import {FormScrollArea} from "../components/form-scroll-area";

const Google = ({ location }: PageProps): React.ReactElement => (
  <Chakra.Box width="100%">
    <Seo title="Google" location={location} />
    <Chakra.Flex
      width="100%"
      flexDir={{ base: "column", lg: "row" }}
      align="start"
      justify="center"
      position="sticky"
      top="0"
      bottom="0"
      left="0"
      right="0"
      overflowY="hidden"
      height="100%"
    >
      <GoogleOptions />
      <Chakra.Flex
        flexDirection="column"
        width="100%"
        justifyContent="start"
        m="auto"
        pb={{ base: "50px", lg: "0" }}
        px={{ base: "20px", lg: "0" }}
      >
          <FormScrollArea>
              <GoogleMockup />
          </FormScrollArea>
      </Chakra.Flex>
    </Chakra.Flex>
    <NextPageButton as={Link} to="/facebook">
      Next Step
    </NextPageButton>
  </Chakra.Box>
);

export default Google;
